import Layout from '@/layout';
var plantBaseRouter = {
  path: '/plantBase',
  component: Layout,
  redirect: '/plantBase/index',
  name: 'PlantBase',
  meta: {
    title: '种植基地',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/plantBase/index');
    },
    name: 'PlantBase',
    meta: {
      title: '基地列表',
      icon: ''
    }
  }, {
    path: 'createBase/:id?',
    component: function component() {
      return import('@/views/plantBase/createBase');
    },
    name: 'PlantBaseEdit',
    meta: {
      title: '基地编辑',
      icon: ''
    }
  }]
};
export default plantBaseRouter;