//
//
//
//
//
//
//
//
//
//
//
//
//

import * as systemConfigApi from '@/api/systemConfig.js';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'Vue Element Admin',
      siteLogoLeftTop: '',
      //左上角logo大
      siteLogoSquare: '' //左上角logo小
    };
  },
  mounted: function mounted() {
    this.getLogo();
  },
  methods: {
    //获取左上角菜单logo
    getLogo: function getLogo() {
      var _this = this;
      systemConfigApi.getSiteLogoApi().then(function (data) {
        _this.siteLogoLeftTop = data.siteLogoLeftTop;
        _this.siteLogoSquare = data.siteLogoSquare;
      });
    }
  }
};