//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { marketingListApi, couponUserApi, marketingSendApi } from '@/api/marketing';
export default {
  name: 'CouponList',
  props: {
    handle: {
      type: String,
      default: ''
    },
    couponData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    keyNum: {
      type: Number,
      default: 0
    },
    userIds: {
      type: String,
      default: ''
    },
    userType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        keywords: '',
        type: ''
      },
      multipleSelection: [],
      multipleSelectionAll: [],
      idKey: 'id',
      nextPageFlag: false,
      attr: []
    };
  },
  watch: {
    keyNum: {
      deep: true,
      handler: function handler(val) {
        this.getList();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.tableFrom.page = 1;
    this.getList();
    if (!this.couponData) return;
    this.couponData.forEach(function (row) {
      _this.$refs.table.toggleRowSelection(row);
    });
  },
  methods: {
    close: function close() {
      this.multipleSelection = [];
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this2 = this;
      this.multipleSelection = val;
      setTimeout(function () {
        _this2.changePageCoreRecordData();
      }, 50);
    },
    // 设置选中的方法
    setSelectRow: function setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      // 标识当前行的唯一键的名称
      var idKey = this.idKey;
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      this.$refs.table.clearSelection();
      for (var i = 0; i < this.tableData.data.length; i++) {
        if (selectAllIds.indexOf(this.tableData.data[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.table.toggleRowSelection(this.tableData.data[i], true);
        }
      }
    },
    // 记忆选择核心方法
    changePageCoreRecordData: function changePageCoreRecordData() {
      // 标识当前行的唯一键的名称
      var idKey = this.idKey;
      var that = this;
      // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
      if (this.multipleSelectionAll.length <= 0) {
        this.multipleSelectionAll = this.multipleSelection;
        return;
      }
      // 总选择里面的key集合
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      var selectIds = [];
      // 获取当前页选中的id
      this.multipleSelection.forEach(function (row) {
        selectIds.push(row[idKey]);
        // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
        if (selectAllIds.indexOf(row[idKey]) < 0) {
          that.multipleSelectionAll.push(row);
        }
      });
      var noSelectIds = [];
      // 得到当前页没有选中的id
      this.tableData.data.forEach(function (row) {
        if (selectIds.indexOf(row[idKey]) < 0) {
          noSelectIds.push(row[idKey]);
        }
      });
      noSelectIds.forEach(function (id) {
        if (selectAllIds.indexOf(id) >= 0) {
          for (var i = 0; i < that.multipleSelectionAll.length; i++) {
            if (that.multipleSelectionAll[i][idKey] == id) {
              // 如果总选择中有未被选中的，那么就删除这条
              that.multipleSelectionAll.splice(i, 1);
              break;
            }
          }
        }
      });
    },
    ok: function ok() {
      if (this.multipleSelection.length > 0) {
        this.$emit('getCouponId', this.multipleSelectionAll);
        this.close();
      } else {
        this.$message.warning('请先选择优惠劵');
      }
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.userType ? this.tableFrom.type = 1 : this.tableFrom.type = 3;
      marketingSendApi(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
        _this3.$nextTick(function () {
          this.setSelectRow(); // 调用跨页选中方法
        });
      }).catch(function (res) {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.changePageCoreRecordData();
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.changePageCoreRecordData();
      this.tableFrom.limit = val;
      this.getList();
    },
    // 发送
    sendGrant: function sendGrant(id) {
      var _this4 = this;
      this.$modalSure('发送优惠劵吗').then(function () {
        couponUserApi({
          couponId: id,
          uid: _this4.userIds
        }).then(function () {
          _this4.$message.success('发送成功');
          _this4.getList();
        });
      });
    }
  }
};