function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toArray(r) { return _arrayWithHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { productLstApi, productDeleteApi, categoryApi, putOnShellApi, offShellApi, productHeadersApi } from '@/api/store';
export default {
  name: 'GoodList',
  props: {
    handleNum: {
      type: String,
      default: ''
    },
    checked: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      templateRadio: 0,
      merCateList: [],
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
        // disabled: 'status'
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        cateId: '',
        keywords: '',
        type: '1'
      },
      imgList: [],
      multipleSelection: [],
      checkedPage: [],
      isChecked: false,
      isIndex: 0,
      checkBox: [],
      checkedIds: [] // 订单当前页选中的数据
    };
  },
  mounted: function mounted() {
    this.getList();
    if (this.checked.length) {
      var _this$checked = _toArray(this.checked),
        arr2 = _this$checked.slice(0);
      this.checkBox = arr2;
      this.checkedIds = arr2.map(function (item) {
        return item.id;
      });
    }
    this.getCategorySelect();
  },
  methods: {
    close: function close() {
      this.$emit('close');
    },
    onInput: function onInput(e) {
      this.$forceUpdate();
    },
    changeType: function changeType(v) {
      this.isChecked = v;
      var index = this.checkedPage.indexOf(this.tableFrom.page);
      this.isIndex = index;
      this.checkedPage.push(this.tableFrom.page);
      if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId(v);
    },
    changeOne: function changeOne(v, order) {
      if (v) {
        var index = this.checkedIds.indexOf(order.id);
        if (index === -1) {
          this.checkedIds.push(order.id);
          this.checkBox.push(order);
        }
      } else {
        var _index = this.checkedIds.indexOf(order.id);
        if (_index > -1) {
          this.checkedIds.splice(_index, 1);
          this.checkBox.splice(_index, 1);
        }
      }
    },
    syncCheckedId: function syncCheckedId(o) {
      var _this = this;
      var ids = this.tableData.data.map(function (v) {
        return v.id;
      });
      if (o) {
        this.tableData.data.forEach(function (item) {
          var index = _this.checkedIds.indexOf(item.id);
          if (index === -1) {
            _this.checkedIds.push(item.id);
            _this.checkBox.push(item);
          }
        });
      } else {
        this.tableData.data.forEach(function (item) {
          var index = _this.checkedIds.indexOf(item.id);
          if (index > -1) {
            _this.checkedIds.splice(index, 1);
            _this.checkBox.splice(index, 1);
          }
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var tables = [];
      val.map(function (item) {
        tables.push({
          src: item.image,
          id: item.id
        });
      });
      this.multipleSelection = tables;
    },
    ok: function ok() {
      this.$emit('getStoreItem', this.checkBox);
    },
    getTemplateRow: function getTemplateRow(row) {
      this.$emit('getStoreItem', row);
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this2 = this;
      categoryApi({
        status: -1,
        type: 1
      }).then(function (res) {
        _this2.merCateList = _this2.addDisabled(res);
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    //限制商品分类只能选择开启的
    addDisabled: function addDisabled(dropdownList) {
      var _this3 = this;
      var list = [];
      try {
        dropdownList.forEach(function (e, index) {
          var e_new = {
            id: e.id,
            name: e.name,
            level: e.level,
            pid: e.pid,
            sort: e.sort,
            status: e.status
          };
          if (!e.status) {
            e_new = _objectSpread(_objectSpread({}, e_new), {}, {
              disabled: true
            });
          }
          if (e.child) {
            var childList = _this3.addDisabled(e.child);
            e_new = _objectSpread(_objectSpread({}, e_new), {}, {
              child: childList
            });
          }
          list.push(e_new);
        });
      } catch (error) {
        console.log(error);
        return [];
      }
      return list;
    },
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      productLstApi(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        // res.list.map((item) => {
        //   this.imgList.push(item.image)
        // })
        _this4.tableData.data.forEach(function (item) {
          _this4.checked.forEach(function (element) {
            if (Number(item.id) === Number(element.id)) {
              _this4.$nextTick(function () {
                _this4.$refs.multipleTable.toggleRowSelection(item, true);
              });
            }
          });
        });
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};